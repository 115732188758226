import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import PartService from "./parts-service";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import Utility from "../../../shared/utility";
import BackToTop from "../../common/back-to-top.vue";
import commonAPIService from "../../common/js/common-api-service";
export default {
  data: () => ({
    userId: EncryptUtility.localStorageDecrypt("userID"),
    actionList: [],
    mainPage: "",
    favouriteList: [],
    pageUrl: "",
    subPage: "",
    showAdd: false,
    subPageUrl: "",
    backSlash: true,
    backSlashParent: true,
    showEdit: true,
    isFormValid: false,
    projectList: [],
    partTypeList: [],
    search: "",
    partsID: "",
    partTypeKey: "",
    substitutionPartListDialog: false,
    substitutionSaveDisabled: false,
    listSubstitutionDetails: [],
    partNumberSearch: "",
    valPartNumFill: false,
    showPartDetails: false,
    partNumSearched: false,
    substitutionList: [],
    numberPart: "",
    descriptionPart: "",
    partNumWarehouse: "",
    editSubstitutionDet: false,
    warehousePartNumList: [],
    warehouseList: [],
    sequence: 0,
    part_key: "",
    warehouseDialog: false,
    primaryName: "",
    listWarehouse: [],
    partClassList: [],
    oemList: [],
    odmList: [],
    modelList: [],
    oemModelList: [],
    saveWarehouseData: false,
    editWarehouseData: false,
    enableSalesOrderItems: false,
    enablePurchaseOrderItems: false,
    saveSubstitutionDet: false,
    enableSalesOrderValue: false,
    enableSalesOrderShowInUI: false,
    enableSalesOrderEnabled: false,
    soStraightSaleValue: false,
    soStraightSaleShowInUI: false,
    soStraightSaleEnabled: false,
    soAdvancedExchangeValue: false,
    soAdvancedExchangeShowInUI: false,
    soAdvancedExchangeEnabled: false,
    soExchangeValue: false,
    soExchangeShowInUI: false,
    soExchangeEnabled: false,
    soCustRepairValue: false,
    soCustRepairShowInUI: false,
    soCustRepairEnabled: false,
    soStraightReturnValue: false,
    soStraightReturnShowInUI: false,
    soStraightReturnEnabled: false,
    enablePurchaseOrdersValue: false,
    enablePurchaseOrdersShowInUI: false,
    enablePurchaseOrdersEnabled: false,
    poStraightPurchaseValue: false,
    poStraightPurchaseShowInUI: false,
    poStraightPurchaseEnabled: false,
    poAdvanceExchangeValue: false,
    poAdvanceExchangeShowInUI: false,
    poAdvanceExchangeEnabled: false,
    poExchangeValue: false,
    poExchangeShowInUI: false,
    poExchangeEnabled: false,
    poStockRepairValue: false,
    poStockRepairShowInUI: false,
    poStockRepairEnabled: false,
    poPOReturnValue: false,
    poPOReturnShowInUI: false,
    poPOReturnEnabled: false,
    bcnValue: false,
    bcnShowInUI: false,
    bcnEnabled: false,
    autoGenerateBCNValue: false,
    autoGenerateBCNShowInUI: false,
    autoGenerateBCNEnabled: false,
    autoGeneratedPrefixValue: "",
    autoGeneratedPrefixShowInUI: false,
    autoGeneratedPrefixEnabled: false,
    serialNoCapturedValue: false,
    serialNoCapturedShowInUI: false,
    serialNoCapturedEnabled: false,
    gobiValue: false,
    gobiShowInUI: false,
    gobiEnabled: false,
    esnIMEIValue: false,
    esnIMEIShowInUI: false,
    esnIMEIEnabled: false,
    ICCIDCapturedValue: false,
    ICCIDCapturedShowInUI: false,
    ICCIDCapturedEnabled: false,
    boxLabelValue: false,
    boxLabelShowInUI: false,
    boxLabelEnabled: false,
    printCommentSheetValue: false,
    printCommentSheetShowInUI: false,
    printCommentSheetEnabled: false,
    promptForNewProductValue: false,
    promptForNewProductShowInUI: false,
    promptForNewProductEnabled: false,
    activateStrikeRuleValue: false,
    activateStrikeRuleShowInUI: false,
    activateStrikeRuleEnabled: false,
    returnWaybillLabelRequiredValue: false,
    returnWaybillLabelRequiredShowInUI: false,
    returnWaybillLabelRequiredEnabled: false,
    promptRevLevelValue: false,
    promptRevLevelShowInUI: false,
    promptRevLevelEnabled: false,
    promptFirmLevelValue: false,
    promptFirmLevelShowInUI: false,
    promptFirmLevelEnabled: false,
    thirdStrikeValue: false,
    thirdStrikeShowInUI: false,
    thirdStrikeEnabled: false,
    minRevLevelValue: "",
    minRevLevelShowInUI: false,
    minRevLevelEnabled: false,
    CRAValue: false,
    CRAShowInUI: false,
    CRAEnabled: true,
    DNCRCVValue: false,
    DNCRCVShowInUI: false,
    DNCRCVEnabled: true,
    IFIRValue: false,
    IFIRShowInUI: false,
    IFIREnabled: true,
    IFIRDaysValue: 0,
    IFIRDaysShowInUI: false,
    IFIRDaysEnabled: true,
    returnsToScrapValue: false,
    returnsToScrapShowInUI: false,
    returnsToScrapEnabled: true,
    DNCBypassScrapValue: false,
    DNCBypassScrapShowInUI: false,
    DNCBypassScrapEnabled: true,
    initialTestTriageValue: false,
    initialTestTriageShowInUI: false,
    initialTestTriageEnabled: true,
    iwTestPathValue: false,
    iwTestPathShowInUI: false,
    iwTestPathEnabled: true,
    owTestPathValue: false,
    owTestPathShowInUI: false,
    owTestPathEnabled: true,
    finalTestPreValue: false,
    finalTestPreShowInUI: false,
    finalTestPreEnabled: true,
    owRepairValue: false,
    owRepairShowInUI: false,
    owRepairEnabled: true,
    iwRepairValue: false,
    iwRepairShowInUI: false,
    iwRepairEnabled: true,
    waitingForPartsValue: false,
    waitingForPartsShowInUI: false,
    waitingForPartsEnabled: true,
    firmwareValue: false,
    firmwareShowInUI: false,
    firmwareEnabled: true,
    finalTestPostValue: false,
    finalTestPostShowInUI: false,
    finalTestPostEnabled: true,
    engineeringEvalValue: false,
    engineeringEvalShowInUI: false,
    engineeringEvalEnabled: true,
    wipeSanitizeValue: false,
    wipeSanitizeShowInUI: false,
    wipeSanitizeEnabled: true,
    cosmeticInspectionValue: false,
    cosmeticInspectionShowInUI: false,
    cosmeticInspectionEnabled: true,
    auditValue: false,
    auditShowInUI: false,
    auditEnabled: true,
    auditSamplingRateValue: "",
    auditSamplingRateShowInUI: false,
    auditSamplingRateEnabled: true,
    harvestValue: false,
    harvestShowInUI: false,
    validatePartsCreate: true,
    harvestEnabled: true,
    bucketDialog: false,
    subPartDelete: false,
    wareDuplicate: 0,
    subDuplicate: 0,
    bucketDetails: "",
    searchBucketDetails: "",
    bucketItems: [],
    optionsBuild: [],
    finalWarehouseData: [],
    listMemberPartDetails: [],
    panel: [0, 1, 2, 3],
    selectedCountry: "",
    countryList: [],
    wareHeaders: [
      {
        text: "Warehouse",
        align: "start",
        value: "ware",
        class: "primary customwhite--text",
      },
      {
        text: "Business",
        value: "business",
        class: "primary customwhite--text",
      },
      {
        text: "Warehouse Type",
        value: "whse_type",
        class: "primary customwhite--text",
      },
      {
        text: "Warehouse Location",
        value: "location",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "Actions",
        class: "primary customwhite--text",
      },
    ],
    headersSubstitution: [
      {
        text: "Part Number",
        align: "start",
        value: "part_num",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "part_desc",
        class: "primary customwhite--text",
      },
      {
        text: "Warehouse",
        value: "ware",
        class: "primary customwhite--text",
      },
    ],
    editData: [
      {
        part_id: 0,
        proj_code: "",
        project: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        part_type: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        part_num: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        part_desc: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        isActive: [
          {
            show_in_ui: true,
            enabled: true,
            value: true,
          },
        ],
        part_class: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        oem: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        odm: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        oem_part: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        model: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        oem_model: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        unit_of_measure: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        bin_type: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        bin_limit: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        packout_ref: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        box_label_size: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        height: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        width: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        length: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        weight: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        daily_usage: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        demand: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        unit_cost: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        sale_price: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        exchange_price: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        repair_price: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        so_warranty_days: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        fgi_dsi_cutoff: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        oem_dsi_cutoff: [
          {
            enabled: false,
            value: 0,
          },
        ],
        oem_parts_per_skid: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        cf_buffer_qty: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        sales_orders: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        so_straight_sale: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        so_advanced_exchange: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        so_exchange: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        so_customer_repair: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        so_straight_return: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        purchase_orders: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        po_straight_purchase: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        po_advanced_exchange: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        po_exchange: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        po_stock_repair: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        po_return: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        bcn: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        auto_generate_bcn: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        auto_generated_prefix: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        serial_number: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        gobi: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        esn_imei: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        iccid: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        box_label: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        print_comment_sheet: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        prompt_for_new: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        third_strike: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        third_strike_visit: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        return_waybill_required: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        revision_level: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        minimum_revision: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        firmware_revision: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        kitting: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        eco: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        build_options: [
          {
            show_in_ui: false,
            enabled: false,
            build_selections: [
              {
                displaystring: "",
                list_id: 0,
                sequence: 0,
              },
            ],
          },
        ],
        warehouses: [
          {
            show_in_ui: false,
            enabled: false,
            warehouse_list: [
              {
                pware_id: 0,
                part_key: 0,
                bu_key: 0,
                ware_key: 0,
                ware: "",
                business: "",
                location: "",
                whse_type: "",
                fgi: 0,
                tbe: 0,
                mrb: 0,
                scp: 0,
                wip: 0,
                on_po: 0,
                allocated: 0,
                backlog: 0,
                available: 0,
              },
            ],
          },
        ],
        sub_parts: [
          {
            show_in_ui: false,
            enabled: false,
            sub_part_list: [
              {
                sub_part_key: 0,
                part_num: "",
                ware_key: 0,
                ware: "",
                sequence: 1,
              },
            ],
          },
        ],
        bom: [
          {
            show_in_ui: false,
            enabled: false,
            bom_part_list: [],
          },
        ],
        cname: "",
        cdatetime: "",
        ename: "",
        edatetime: "",
        any_activity: false,
        any_bcn_activity: false,
        can_delete: "",
        country_id: 0,
      },
    ],
    dataWarehouse: [
      {
        sequence: 0,
        pware_id: 0,
        part_key: 0,
        bu_key: 0,
        ware_key: 0,
        ware: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        business: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        whse_type: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        can_delete: false,
        tab1: [
          {
            enabled: false,
            whse_info: [
              {
                whse_owner: [
                  {
                    company: "",
                    address1: "",
                    city: "",
                    state: "",
                    country: "",
                  },
                ],
                whse_location: [
                  {
                    company: "",
                    address1: "",
                    city: "",
                    state: "",
                    country: "",
                  },
                ],
              },
            ],
            fgi: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            tbe: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            mrb: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            scrap: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            wip: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            onorder_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            allocated: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            backordered_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            onhand_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            available: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            unit_avg_cost: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            unit_cost_on_latest_po: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            lead_time: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            avg_lead_time: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            dsi: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            daily_usage: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            reorder_point: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            order_point_calc: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
          },
        ],
        tab2: [
          {
            enabled: false,
            prompts: [
              {
                cra: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                dnc_rcv: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ifir: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ifir_days: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                returns_to_scrap: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                dnc_bypass_scrap: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                initial_test: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                iw_test_path: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                ow_test_path: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                final_test_pre: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ow_repair: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                iw_repair: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                waiting_for_parts: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                firmware: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                final_test_post: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                engineering_eval: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                wipe_sanitize: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                cosmetic_insp: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                audit: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                audit_sampling_rate: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                harvest: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    headersMemberPart: [
      {
        text: "Member Part#",
        align: "start",
        value: "part_num",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "part_desc",
        class: "primary customwhite--text",
      },
      {
        text: "Quantity",
        value: "qty",
        class: "primary customwhite--text",
      },
      {
        text: "Is Primary Part",
        value: "primary_pn",
        class: "primary customwhite--text",
      },
      {
        text: "Status",
        value: "active",
        class: "primary customwhite--text",
      },
    ],
    createdDate: "",
    editedDate: "",
    fab: false,
    maxInputValue: [(v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647"],
    keywords: ["PLASMA TV", "TV", "%DISPLAY%", "%MONITOR%", "PROJECTOR", "LCD", "LCD TV"],
    matchingClassIds: [],
    displaySizeVal: 0,
    displaySizeShow: false,
  }),
  async created() {
    this.primaryName = this.$cookies.get("primaryName");
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    let actionData = await AddFavourite.getParentPageAction(this.userId, this.subPageUrl);
    this.actionList = actionData?.actionList;
    this.partsID = atob(this.$route.params.id);
    if (this.actionSetter("UpdatePartsEdit")) {
      this.showEdit = true;
    } else {
      this.showEdit = false;
    }
    let countryData = await commonAPIService.countryListNew(this.userId, "", 1, "get");
    this.countryList = countryData.CountryInfo;
    this.getPartHeaderByID(this.partsID);
  },

  mounted() { },

  methods: {
    //to check if its a number of not
    isNumber(event, loc) {
      if (loc == "number") {
        let value = Utility.isNumber(event)
        return value
      }
      else {
        let value = Utility.isNumberWithPeriod(event)
        return value
      }
    },
    // Scroll Check method
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    // To go to the top
    toTop() {
      this.$vuetify.goTo(0);
    },
    //Action Setter
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Edit button click event in breadcrumb
    editClick() {
      this.$router.push({
        name: "appPartsUpdate",
        params: {
          id: btoa(this.partsID.toString()),
        },
      });
      // this.$router.push(`/parts-update/${btoa(this.partsID.toString())}`);
    },
    //Get Parts Header based on Part ID
    async getPartHeaderByID(part_id) {
      let partHeaderData = await PartService.getPartHeaderByID("get", this.userId, part_id);
      if (partHeaderData) {
        Object.assign(this.editData, partHeaderData);
      }
      this.getPartClass();
      this.getOEMDetails();
      this.getODMDetails();
      this.getModelDetails();
      this.getOEMModelDetails();
      this.getUOMDetails();
      this.getBinTypeDetails();
      this.getLabelSizeDetails();
      this.getBuildActionDetails();
      if (partHeaderData) {
        //Object.assign(this.editData, partHeaderData);
        let projectData = await commonAPIService.getProject("get", this.userId);
        this.projectList = projectData.ProjectInfo;
        let partTypeData = await PartService.getPartType("get", this.editData[0].project[0].value, true);
        this.partTypeList = partTypeData;
        this.warehouseList = this.editData[0]?.warehouses[0]?.warehouse_list.map((obj, index) => {
          return {
            ...obj,
            sequence: index + 1,
            isedited: false,
          };
        });
        this.listMemberPartDetails = this.editData[0]?.bom[0]?.bom_part_list;
        this.listSubstitutionDetails = this.editData[0]?.sub_parts[0]?.sub_part_list;
        this.enableSalesOrderValue = this.editData[0].sales_orders[0].value;
        this.enableSalesOrderShowInUI = this.editData[0].sales_orders[0].show_in_ui;
        this.enableSalesOrderEnabled = this.editData[0].sales_orders[0].enabled;
        this.soStraightSaleValue = this.editData[0].sales_orders[0].value;
        this.soStraightSaleShowInUI = this.editData[0].so_straight_sale[0].show_in_ui;
        this.soStraightSaleEnabled = this.editData[0].so_straight_sale[0].enabled;
        this.soAdvancedExchangeValue = this.editData[0].so_advanced_exchange[0].value;
        this.soAdvancedExchangeShowInUI = this.editData[0].so_advanced_exchange[0].show_in_ui;
        this.soAdvancedExchangeEnabled = this.editData[0].so_advanced_exchange[0].enabled;
        this.soExchangeValue = this.editData[0].so_exchange[0].value;
        this.soExchangeShowInUI = this.editData[0].so_exchange[0].show_in_ui;
        this.soExchangeEnabled = this.editData[0].so_exchange[0].enabled;
        this.soCustRepairValue = this.editData[0].so_customer_repair[0].value;
        this.soCustRepairShowInUI = this.editData[0].so_customer_repair[0].show_in_ui;
        this.soCustRepairEnabled = this.editData[0].so_customer_repair[0].enabled;
        this.soStraightReturnValue = this.editData[0].so_straight_return[0].value;
        this.soStraightReturnShowInUI = this.editData[0].so_straight_return[0].show_in_ui;
        this.soStraightReturnEnabled = this.editData[0].so_straight_return[0].enabled;
        this.enablePurchaseOrdersValue = this.editData[0].purchase_orders[0].value;
        this.enablePurchaseOrdersShowInUI = this.editData[0].purchase_orders[0].show_in_ui;
        this.enablePurchaseOrdersEnabled = this.editData[0].purchase_orders[0].enabled;
        this.poStraightPurchaseValue = this.editData[0].po_straight_purchase[0].value;
        this.poStraightPurchaseShowInUI = this.editData[0].po_straight_purchase[0].show_in_ui;
        this.poStraightPurchaseEnabled = this.editData[0].po_straight_purchase[0].enabled;
        this.poAdvanceExchangeValue = this.editData[0].po_advanced_exchange[0].value;
        this.poAdvanceExchangeShowInUI = this.editData[0].po_advanced_exchange[0].show_in_ui;
        this.poAdvanceExchangeEnabled = this.editData[0].po_advanced_exchange[0].enabled;
        this.poExchangeValue = this.editData[0].po_exchange[0].value;
        this.poExchangeShowInUI = this.editData[0].po_exchange[0].show_in_ui;
        this.poExchangeEnabled = this.editData[0].po_exchange[0].enabled;
        this.poStockRepairValue = this.editData[0].po_stock_repair[0].value;
        this.poStockRepairShowInUI = this.editData[0].po_stock_repair[0].show_in_ui;
        this.poStockRepairEnabled = this.editData[0].po_stock_repair[0].enabled;
        this.poPOReturnValue = this.editData[0].po_return[0].value;
        this.poPOReturnShowInUI = this.editData[0].po_return[0].show_in_ui;
        this.poPOReturnEnabled = this.editData[0].po_return[0].enabled;
        this.bcnValue = this.editData[0].bcn[0].value;
        this.bcnShowInUI = this.editData[0].bcn[0].show_in_ui;
        this.bcnEnabled = this.editData[0].bcn[0].enabled;
        this.autoGenerateBCNValue = this.editData[0].auto_generate_bcn[0].value;
        this.autoGenerateBCNShowInUI = this.editData[0].auto_generate_bcn[0].show_in_ui;
        this.autoGenerateBCNEnabled = this.editData[0].auto_generate_bcn[0].enabled;
        this.autoGeneratedPrefixValue = this.editData[0].auto_generated_prefix[0].value;
        this.autoGeneratedPrefixShowInUI = this.editData[0].auto_generated_prefix[0].show_in_ui;
        this.autoGeneratedPrefixEnabled = this.editData[0].auto_generated_prefix[0].enabled;
        this.serialNoCapturedValue = this.editData[0].serial_number[0].value;
        this.serialNoCapturedShowInUI = this.editData[0].serial_number[0].show_in_ui;
        this.serialNoCapturedEnabled = this.editData[0].serial_number[0].enabled;
        this.gobiValue = this.editData[0].gobi[0].value;
        this.gobiShowInUI = this.editData[0].gobi[0].show_in_ui;
        this.gobiEnabled = this.editData[0].gobi[0].enabled;
        this.esnIMEIValue = this.editData[0].esn_imei[0].value;
        this.esnIMEIShowInUI = this.editData[0].esn_imei[0].show_in_ui;
        this.esnIMEIEnabled = this.editData[0].esn_imei[0].enabled;
        this.ICCIDCapturedValue = this.editData[0].iccid[0].value;
        this.ICCIDCapturedShowInUI = this.editData[0].iccid[0].show_in_ui;
        this.ICCIDCapturedEnabled = this.editData[0].iccid[0].enabled;
        this.boxLabelValue = this.editData[0].box_label[0].value;
        this.boxLabelShowInUI = this.editData[0].box_label[0].show_in_ui;
        this.boxLabelEnabled = this.editData[0].box_label[0].enabled;
        this.printCommentSheetValue = this.editData[0].print_comment_sheet[0].value;
        this.printCommentSheetShowInUI = this.editData[0].print_comment_sheet[0].show_in_ui;
        this.printCommentSheetEnabled = this.editData[0].print_comment_sheet[0].enabled;
        this.promptForNewProductValue = this.editData[0].prompt_for_new[0].value;
        this.promptForNewProductShowInUI = this.editData[0].prompt_for_new[0].show_in_ui;
        this.promptForNewProductEnabled = this.editData[0].prompt_for_new[0].enabled;
        this.activateStrikeRuleValue = this.editData[0].third_strike[0].value;
        this.activateStrikeRuleShowInUI = this.editData[0].third_strike[0].show_in_ui;
        this.activateStrikeRuleEnabled = this.editData[0].third_strike[0].enabled;
        this.returnWaybillLabelRequiredValue = this.editData[0].return_waybill_required[0].value;
        this.returnWaybillLabelRequiredShowInUI = this.editData[0].return_waybill_required[0].show_in_ui;
        this.returnWaybillLabelRequiredEnabled = this.editData[0].return_waybill_required[0].enabled;
        this.promptRevLevelValue = this.editData[0].revision_level[0].value;
        this.promptRevLevelShowInUI = this.editData[0].revision_level[0].show_in_ui;
        this.promptRevLevelEnabled = this.editData[0].revision_level[0].enabled;
        this.promptFirmLevelValue = this.editData[0].firmware_revision[0].value;
        this.promptFirmLevelShowInUI = this.editData[0].firmware_revision[0].show_in_ui;
        this.promptFirmLevelEnabled = this.editData[0].firmware_revision[0].enabled;
        this.thirdStrikeValue = this.editData[0].third_strike_visit[0].value;
        this.thirdStrikeShowInUI = this.editData[0].third_strike_visit[0].show_in_ui;
        this.thirdStrikeEnabled = this.editData[0].third_strike_visit[0].enabled;
        this.minRevLevelValue = this.editData[0].minimum_revision[0].value;
        this.minRevLevelShowInUI = this.editData[0].minimum_revision[0].show_in_ui;
        this.minRevLevelEnabled = this.editData[0].minimum_revision[0].enabled;
        this.oemPartNum = this.editData[0].oem_part[0].value;
        const items = this.editData[0].build_options[0].build_selections.filter((obj) => obj.sequence !== 0);
        const sortedItems = items.sort((a, b) => a.sequence - b.sequence);
        this.editData[0].build_options[0].build_selections = sortedItems;
        this.createdDate = Utility.convertESTToLocal(this.editData[0]?.cdatetime);
        this.editedDate = Utility.convertESTToLocal(this.editData[0]?.edatetime);
        this.selectedCountry = this.editData[0]?.country_id;
        this.displaySizeVal = this.editData[0]?.size;
        if (
          this.editData[0].part_class[0].value !== null &&
          this.editData[0].part_class[0].value !== undefined &&
          this.editData[0].part_class[0].value !== ""
        ) {
          this.showDisplaySize();
        }
      }
    },
    //Get Part Class
    async getPartClass() {
      if (this.editData[0].project[0].value !== null && this.editData[0].project[0].value !== undefined) {
        let partClassData = await PartService.getPartClass("get", this.userId, this.editData[0].project[0].value);
        this.matchingClassIds = this.compareClasses(partClassData, this.keywords);
        this.partClassList = partClassData;
      }
    },
    //Comparing and getting the Part Class keys
    compareClasses(data, keywords) {
      const matchingIds = [];
      for (const item of data) {
        const itemClass = item.class.toUpperCase();
        let isMatch = false;
        for (const keyword of keywords) {
          const normalizedKeyword = keyword.toUpperCase();
          if (!isMatch && (itemClass === normalizedKeyword || itemClass.includes(normalizedKeyword))) {
            isMatch = true;
            break;
          }
        }
        if (isMatch) {
          matchingIds.push(item.class_id);
        }
      }
      return matchingIds;
    },
    //Whether to show display size or not
    showDisplaySize() {
      let display = this.matchingClassIds.find((element) => element == this.editData[0].part_class[0].value);
      if (display !== undefined && display !== null) {
        this.displaySizeShow = true;
      } else {
        this.displaySizeShow = false;
        this.displaySizeVal = 0;
      }
    },
    //Get OEM Details
    async getOEMDetails() {
      let OEMData = await PartService.getOEMDetails("get", this.userId, this.editData[0].project[0].value);
      this.oemList = OEMData;
    },
    //Get ODM Details
    async getODMDetails() {
      let ODMData = await PartService.getODMDetails("get", this.userId, this.editData[0].project[0].value);
      this.odmList = ODMData;
    },
    //Get Model Details
    async getModelDetails() {
      let modelData = await PartService.getModelDetails("get", this.userId, this.editData[0].project[0].value);
      this.modelList = modelData;
    },
    //Get UOM Details
    async getUOMDetails() {
      let UOMData = await PartService.getUOMDetails("get");
      this.unitOfMeasureList = UOMData;
    },
    //Get Bin Type Details
    async getBinTypeDetails() {
      let binTypeData = await PartService.getBinTypeDetails("get");
      this.binTypeList = binTypeData;
    },
    //Get Label Size Details
    async getLabelSizeDetails() {
      let labelSizeData = await PartService.getLabelSizeDetails("get");
      this.boxLabelSizeList = labelSizeData;
    },
    //Get Build Action Details
    async getBuildActionDetails() {
      let buildActionData = await PartService.getBuildActionDetails("get");
      this.buildOptionsList = buildActionData;
    },
    //Get OEM Model Details
    async getOEMModelDetails() {
      let OEMModelData = await PartService.getOEMModelDetails("get", this.userId, this.editData[0].project[0].value);
      this.oemModelList = OEMModelData;
    },
    //Close dialog
    closeDialog() {
      this.warehouseDialog = false;
    },
    //Clear Data Warehouse
    clearDataWarehouse() {
      this.CRAValue = false;
      this.CRAShowInUI = true;
      this.CRAEnabled = true;
      this.DNCRCVValue = false;
      this.DNCRCVShowInUI = true;
      this.DNCRCVEnabled = true;
      this.IFIRValue = false;
      this.IFIRShowInUI = true;
      this.IFIREnabled = true;
      this.IFIRDaysValue = 0;
      this.IFIRDaysShowInUI = true;
      this.IFIRDaysEnabled = false;
      this.returnsToScrapValue = false;
      this.returnsToScrapShowInUI = true;
      this.returnsToScrapEnabled = true;
      this.DNCBypassScrapValue = false;
      this.DNCBypassScrapShowInUI = true;
      this.DNCBypassScrapEnabled = true;
      this.initialTestTriageValue = false;
      this.initialTestTriageShowInUI = true;
      this.initialTestTriageEnabled = true;
      this.iwTestPathValue = false;
      this.iwTestPathShowInUI = true;
      this.iwTestPathEnabled = false;
      this.owTestPathValue = false;
      this.owTestPathShowInUI = true;
      this.owTestPathEnabled = false;
      this.finalTestPreValue = false;
      this.finalTestPreShowInUI = true;
      this.finalTestPreEnabled = true;
      this.owRepairValue = false;
      this.owRepairShowInUI = true;
      this.owRepairEnabled = true;
      this.iwRepairValue = false;
      this.iwRepairShowInUI = true;
      this.iwRepairEnabled = true;
      this.waitingForPartsValue = false;
      this.waitingForPartsShowInUI = true;
      this.waitingForPartsEnabled = true;
      this.firmwareValue = false;
      this.firmwareShowInUI = true;
      this.firmwareEnabled = true;
      this.finalTestPostValue = false;
      this.finalTestPostShowInUI = true;
      this.finalTestPostEnabled = true;
      this.engineeringEvalValue = false;
      this.engineeringEvalShowInUI = true;
      this.engineeringEvalEnabled = true;
      this.wipeSanitizeValue = false;
      this.wipeSanitizeShowInUI = true;
      this.wipeSanitizeEnabled = true;
      this.cosmeticInspectionValue = false;
      this.cosmeticInspectionShowInUI = true;
      this.cosmeticInspectionEnabled = true;
      this.auditValue = false;
      this.auditShowInUI = true;
      this.auditEnabled = true;
      this.auditSamplingRateValue = "";
      this.auditSamplingRateShowInUI = true;
      this.auditSamplingRateEnabled = true;
      this.harvestValue = false;
      this.harvestShowInUI = true;
      this.harvestEnabled = true;
      this.dataWarehouse[0] = {
        sequence: 0,
        pware_id: 0,
        part_key: 0,
        bu_key: 0,
        ware_key: 0,
        ware: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        business: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        whse_type: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        can_delete: false,
        tab1: [
          {
            enabled: false,
            whse_info: [
              {
                whse_owner: [
                  {
                    company: "",
                    address1: "",
                    city: "",
                    state: "",
                    country: "",
                  },
                ],
                whse_location: [
                  {
                    company: "",
                    address1: "",
                    city: "",
                    state: "",
                    country: "",
                  },
                ],
              },
            ],
            fgi: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            tbe: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            mrb: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            scrap: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            wip: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            onorder_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            allocated: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            backordered_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            onhand_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            available: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            unit_avg_cost: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            unit_cost_on_latest_po: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            lead_time: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            avg_lead_time: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            dsi: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            daily_usage: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            reorder_point: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            order_point_calc: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
          },
        ],
        tab2: [
          {
            enabled: false,
            prompts: [
              {
                cra: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                dnc_rcv: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ifir: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ifir_days: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                returns_to_scrap: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                dnc_bypass_scrap: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                initial_test: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                iw_test_path: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                ow_test_path: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                final_test_pre: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ow_repair: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                iw_repair: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                waiting_for_parts: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                firmware: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                final_test_post: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                engineering_eval: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                wipe_sanitize: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                cosmetic_insp: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                audit: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                audit_sampling_rate: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                harvest: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    //Get Test paths
    async getTestPathDetails() {
      let testPathData = await PartService.getTestPathDetails("get");
      this.iwTestPathList = testPathData.iw_test_path;
      this.owTestPathList = testPathData.ow_test_path;
    },
    //Edit Warehouse details
    async warehouseEdit(item) {
      this.clearDataWarehouse();
      this.getTestPathDetails();
      this.tab = 0;
      let partWareHouseData = await PartService.getWareDetails("get", item.pware_id, this.serialNoCapturedValue || this.bcnValue);
      Object.assign(this.dataWarehouse[0], partWareHouseData[0]);
      this.dataWarehouse[0].sequence = item.sequence;
      this.dataWarehouse[0].pware_id = item.pware_id;
      this.dataWarehouse[0].part_key = this.partsID;
      this.CRAValue = partWareHouseData[0].tab2[0].prompts[0].cra[0].value;
      this.DNCRCVValue = partWareHouseData[0].tab2[0].prompts[0].dnc_rcv[0].value;
      this.IFIRValue = partWareHouseData[0].tab2[0].prompts[0].ifir[0].value;
      this.IFIRDaysValue = partWareHouseData[0].tab2[0].prompts[0].ifir_days[0].value;
      this.returnsToScrapValue = partWareHouseData[0].tab2[0].prompts[0].returns_to_scrap[0].value;
      this.DNCBypassScrapValue = partWareHouseData[0].tab2[0].prompts[0].dnc_bypass_scrap[0].value;
      this.initialTestTriageValue = partWareHouseData[0].tab2[0].prompts[0].initial_test[0].value;
      this.iwTestPathValue = parseInt(partWareHouseData[0].tab2[0].prompts[0].iw_test_path[0].value);
      this.owTestPathValue = parseInt(partWareHouseData[0].tab2[0].prompts[0].ow_test_path[0].value);
      this.finalTestPreValue = partWareHouseData[0].tab2[0].prompts[0].final_test_pre[0].value;
      this.owRepairValue = partWareHouseData[0].tab2[0].prompts[0].ow_repair[0].value;
      this.iwRepairValue = partWareHouseData[0].tab2[0].prompts[0].iw_repair[0].value;
      this.waitingForPartsValue = partWareHouseData[0].tab2[0].prompts[0].waiting_for_parts[0].value;
      this.firmwareValue = partWareHouseData[0].tab2[0].prompts[0].firmware[0].value;
      this.finalTestPostValue = partWareHouseData[0].tab2[0].prompts[0].final_test_post[0].value;
      this.engineeringEvalValue = partWareHouseData[0].tab2[0].prompts[0].engineering_eval[0].value;
      this.wipeSanitizeValue = partWareHouseData[0].tab2[0].prompts[0].wipe_sanitize[0].value;
      this.cosmeticInspectionValue = partWareHouseData[0].tab2[0].prompts[0].cosmetic_insp[0].value;
      this.auditValue = partWareHouseData[0].tab2[0].prompts[0].audit[0].value;
      this.auditSamplingRateValue = partWareHouseData[0].tab2[0].prompts[0].audit_sampling_rate[0].value;
      this.harvestValue = partWareHouseData[0].tab2[0].prompts[0].harvest[0].value;
      this.CRAShowInUI = partWareHouseData[0].tab2[0].prompts[0].cra[0].show_in_ui;
      this.DNCRCVShowInUI = partWareHouseData[0].tab2[0].prompts[0].dnc_rcv[0].show_in_ui;
      this.IFIRShowInUI = partWareHouseData[0].tab2[0].prompts[0].ifir[0].show_in_ui;
      this.IFIRDaysShowInUI = partWareHouseData[0].tab2[0].prompts[0].ifir_days[0].show_in_ui;
      this.returnsToScrapShowInUI = partWareHouseData[0].tab2[0].prompts[0].returns_to_scrap[0].show_in_ui;
      this.DNCBypassScrapShowInUI = partWareHouseData[0].tab2[0].prompts[0].dnc_bypass_scrap[0].show_in_ui;
      this.initialTestTriageShowInUI = partWareHouseData[0].tab2[0].prompts[0].initial_test[0].show_in_ui;
      this.iwTestPathShowInUI = partWareHouseData[0].tab2[0].prompts[0].iw_test_path[0].show_in_ui;
      this.owTestPathShowInUI = partWareHouseData[0].tab2[0].prompts[0].ow_test_path[0].show_in_ui;
      this.finalTestPreShowInUI = partWareHouseData[0].tab2[0].prompts[0].final_test_pre[0].show_in_ui;
      this.owRepairShowInUI = partWareHouseData[0].tab2[0].prompts[0].ow_repair[0].show_in_ui;
      this.iwRepairShowInUI = partWareHouseData[0].tab2[0].prompts[0].iw_repair[0].show_in_ui;
      this.waitingForPartsShowInUI = partWareHouseData[0].tab2[0].prompts[0].waiting_for_parts[0].show_in_ui;
      this.firmwareShowInUI = partWareHouseData[0].tab2[0].prompts[0].firmware[0].show_in_ui;
      this.finalTestPostShowInUI = partWareHouseData[0].tab2[0].prompts[0].final_test_post[0].show_in_ui;
      this.engineeringEvalShowInUI = partWareHouseData[0].tab2[0].prompts[0].engineering_eval[0].show_in_ui;
      this.cosmeticInspectionShowInUI = partWareHouseData[0].tab2[0].prompts[0].cosmetic_insp[0].show_in_ui;
      this.auditShowInUI = partWareHouseData[0].tab2[0].prompts[0].audit[0].show_in_ui;
      this.auditSamplingRateShowInUI = partWareHouseData[0].tab2[0].prompts[0].audit_sampling_rate[0].show_in_ui;
      this.harvestShowInUI = partWareHouseData[0].tab2[0].prompts[0].harvest[0].show_in_ui;
      this.warehouseDialog = true;
      this.saveWarehouseData = false;
      this.editWarehouseData = true;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
  },
};
