import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  getPartType(call, proj_key, isAdd) {
    return this.getData(`/pe/part_type_get?proj_key=${proj_key}&isAdd=${isAdd}`, call);
  },
  postValidatePartNum(call, obj) {
    return this.getData("/pe/parts_validate_num", call, obj);
  },
  getPartClassListSearch(call,userId) {
    return this.getData(`pe/parts_editor_getclass?UserId=${userId}`, call);
  },
  postSearchData(call,obj) {
    return this.getData("/pe/parts_editor_search", call, obj);
  },
  getPartClass(call, userId, proj) {
    return this.getData(`/pe/parts_class_get?UserId=${userId}&proj_key=${proj}`, call);
  },
  getOEMDetails(call, userId, projKey) {
    return this.getData(`/pe/parts_oem_get?UserId=${userId}&ProjKey=${projKey}`, call);
  },
  getODMDetails(call, userId, projKey) {
    return this.getData(`/pe/parts_odm_get?UserId=${userId}&ProjKey=${projKey}`, call);
  },
  getModelDetails(call, userId, projKey) {
    return this.getData(`/pe/parts_model_get?UserId=${userId}&ProjKey=${projKey}`, call);
  },
  getOEMModelDetails(call, userId, projKey) {
    return this.getData(`/pe/parts_oem_model_get?UserId=${userId}&ProjKey=${projKey}`, call);
  },
  getUOMDetails(call) {
    return this.getData(`/pe/parts_uom_get`, call);
  },
  getBinTypeDetails(call) {
    return this.getData(`/pe/parts_bin_type_get`, call);
  },
  getLabelSizeDetails(call) {
    return this.getData(`/pe/parts_label_size`, call);
  },
  getBuildActionDetails(call) {
    return this.getData(`/pe/parts_build_action`, call);
  },
  postNewWarehouse(call, obj) {
    return this.getData("/pe/parts_available_ware", call, obj);
  },
  postWarehouseSetup(call, obj) {
    return this.getData("/pe/parts_warehouse_setup", call, obj);
  },
  getTestPathDetails(call) {
    return this.getData(`/pe/parts_ware_test_paths`, call);
  },
  postSubstitutionPartsList(call, obj) {
    return this.getData(`/pe/parts_subpart_search`, call, obj);
  },
  getWarehouseDetails(call, partID) {
    return this.getData(`/pe/parts_subpart_ware?part_id=${partID}`, call);
  },
  getPartHeaderByID(call, user_id, parts_id) {
    return this.getData(`/pe/parts_editor_header?user_id=${user_id}&parts_id=${parts_id}`, call);
  },
  getWareDetails(call, pware_id, isSerialized) {
    return this.getData(`/pe/parts_ware_details?pware_id=${pware_id}&isSerialized=${isSerialized}`, call);
  },
  getPartsEditUISetup(call, ptype_key) {
    return this.getData(`pe/parts_uisetup?ptype_key=${ptype_key}`, call);
  },
  postControlUIDetails(call, obj) {
    return this.getDataWithoutLoader(`/pe/parts_control_ui`, call, obj);
  },
  getPartsBucketQty(call, part_key, ware_key, bucket) {
    return this.getData(`/pe/parts_bucket_qty?part_key=${part_key}&ware_key=${ware_key}&bucket=${bucket}`, call);
  },
  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res.data.body.message != undefined || res.data.body.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.body.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        return JSON.parse(res?.data?.body?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else if (res.data.body.message == null) {
      LoaderDialog.visible = false;
      return JSON.parse('{"message":"NA"}');
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
  async getDataWithoutLoader(url, call, obj, showMessage) {
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
      });
    }
    if (res.data.body.message != undefined || res.data.body.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.body.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        return JSON.parse(res?.data?.body?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else if (res.data.body.message == null) {
      return JSON.parse('{"message":"NA"}');
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.message;
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
};
